.App {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Orbitron", sans-serif;

  .bindapp-main {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;

    .gradient-bg {
      position: absolute;
      background: linear-gradient(80deg, rgba(100, 255, 171, .6), rgba(100, 218, 255, .8));
      top: 50%;
      left: 25%;
      width: 50%;
      height: 25%;
      border-radius: 25%;
      filter: blur(200px);
      transform: translateY(-50%);
      z-index: -1;
    }

    .page {
      padding-top: 66px;
      width: 100%;
      text-align: center;
    }
  }
}