.mainpool-page {
    align-items: center;
    width: 100%;

    .mainpool-page-card {
        width: 90%;
        max-width: 1200px;
        margin: auto;
        padding: 25px 30px 20px;
        background: rgba(255, 255, 255, 0.35);
        border-radius: 5px;
        text-align: center;
        margin-top: 30px;

        .title {
            font-size: 26px;
            font-weight: 600;
            margin-bottom: 15px;
        }

        .mainpool-token-amount-title {
            font-size: 18px;
            font-weight: 600;
        }

        .mainpool-token-amount {
            font-weight: 600;
        }

        .ant-statistic-content-value {
            color: white;
        }

        .mainpool-btn-group {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            .mainpool-btn {
                width: 80%;
                max-width: 152px;
                margin-top: 5px;
            }
        }
    }
}