.table-action {
    cursor: pointer !important;
}

.modal {
    .ant-statistic-content-value {
        color: black !important;
    }

    .ant-statistic-content {
        font-size: 14px !important;
    }
}