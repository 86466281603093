.vesting-page {
    display: flex;
    align-items: center;
    width: 100%;

    .vesting-page-card {
        width: 80%;
        max-width: 600px;
        margin: auto;
        padding: 25px 30px 20px;
        background: rgba(255, 255, 255, 0.35);
        border-radius: 5px;
        text-align: center;

        .title {
            font-size: 26px;
            font-weight: 600;
        }

        .token-amount {
            font-size: 28px;
            color: #00fcfe;
            font-weight: 600;
        }

        .ant-statistic-content-value {
            color: white;
        }

        .vesting-btn {
            width: 80%;
            max-width: 152px;
            margin-top: 5px;
        }
    }
}