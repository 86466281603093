@import '~antd/dist/antd.css';
@import '~bootstrap/scss/bootstrap.scss';

body {
  background: #040610;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.bindapp-btn {
  align-items: center;
  border: 0;
  border-radius: 16px;
  box-shadow: inset 0 -1px 0 0 rgb(14 14 44 / 40%);
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: .03em;
  line-height: 1;
  opacity: 1;
  outline: 0;
  transition: background-color .2s ease 0s, opacity .2s ease 0s;
  height: 36px;
  padding: 0 24px;
  background-color: #1fc7d4;
  color: #fff;

  &:hover {
    opacity: 0.65;
  }

  &:active {
    opacity: .85;
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
    box-shadow: none;
  }
}

.bindapp-modal {
  .modal-content {
    color: #fff;
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #65def8;
    box-shadow: 0px 0px 0px -1px rgb(0 252 254 / 50%), 0px 0px 10px 0px rgb(0 252 254 / 50%), 0px -5px 18px 0px rgb(0 252 254 / 50%);

    .modal-footer {
      button {
        background: rgba(0, 252, 254, 0.8);
        box-shadow: inset 0 -4px 9px rgb(0 252 254 / 50%), inset 0 4px 9px rgb(0 252 254 / 50%);
        border: none;

        &:hover {
          opacity: 0.85;
        }

        &:active {
          opacity: 0.85;
          transform: translateY(1px);
          box-shadow: none;
        }
      }
    }
  }
}

.horizontal-divider {
  border-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .5) 50.82%, hsla(0, 0%, 100%, 0));
  border-image-slice: 1;
}