.admin-page {
    align-items: center;
    width: 100%;

    .admin-page-card {
        width: 90%;
        max-width: 1200px;
        margin: auto;
        padding: 25px 30px 20px;
        background: rgba(255, 255, 255, 0.35);
        border-radius: 5px;
        text-align: center;
        margin-top: 30px;

        .title {
            font-size: 26px;
            text-align: left;
            font-weight: 600;
        }

        .vesting-contract-owner {
            text-align: left;
        }
    }
}