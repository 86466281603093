.main-pool-info {
    .info-data {
        text-align: left;
    }

    .main-pool-info-btn,
    .main-pool-init-btn {
        width: 80%;
        max-width: 280px;
    }
}