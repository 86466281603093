nav {
    .logo-nav {
        display: flex;
        align-items: center;
        font-size: 22px;
        font-weight: 600;
        font-family: "Orbitron", sans-serif;
        letter-spacing: 3px;

        img {
            margin-right: 10px;
            border: 1px solid #495151 !important;
            box-shadow: 0 0px 5px 1px #495151 !important;
            border-radius: 50%;
        }
    }

    .nav-page-link {
        a {
            padding: 5px 8px;
            color: white;
            text-decoration: none;

            &:hover,
            &.active {
                color: #6cdbeb;
                text-decoration: underline;
            }

            &:active {
                transform: translateY(2px);
            }
        }
    }

    .wallet-adapter-button {
        height: 40px;
        background: linear-gradient(175deg, hsla(0, 0%, 100%, .125), rgba(46, 46, 46, .08));
        margin-left: 5px;
    }

    .navbar-custom-button {
        background: linear-gradient(160deg, hsla(0, 0%, 100%, .125), rgba(46, 46, 46, .08));
        color: hsla(0, 0%, 100%, .75);
        border: 0.5px solid hsla(0, 0%, 77%, .35);
        border-radius: 7.5px;
        font-family: Inter, sans-serif;
        font-weight: 600;
        font-size: 13.5px;
        margin: 0 5px;
        padding: 6px 10px;
        cursor: pointer;

        &:active {
            opacity: 0.85;
            transform: translateY(1px);
            box-shadow: none;
        }
    }

    .user-balance {
        background: rgba(0, 252, 254, 0.8);
        box-shadow: inset 0 -4px 9px rgb(0 252 254 / 50%), inset 0 4px 9px rgb(0 252 254 / 50%);
        border-radius: 10px;
        justify-content: center;
        padding: 3px 10px;
        margin-left: 5px;
        margin-right: 5px;
        color: #000;
    }
}